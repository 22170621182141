export default class Locales {
  public static readonly supportedLocales: string[] = [
    "ar-sa",
    "bg-bg",
    "ca-es",
    "cs-cz",
    "da-dk",
    "de-de",
    "el-gr",
    "en-us",
    "es-es",
    "et-ee",
    "eu-es",
    "fi-fi",
    "fr-fr",
    "gl-es",
    "he-il",
    "hi-in",
    "hr-hr",
    "hu-hu",
    "id-id",
    "it-it",
    "ja-jp",
    "kk-kz",
    "ko-kr",
    "lt-lt",
    "lv-lv",
    "ms-my",
    "nb-no",
    "nl-nl",
    "pl-pl",
    "pt-br",
    "pt-pt",
    "ro-ro",
    "ru-ru",
    "sk-sk",
    "sl-si",
    "sr-cyrl-cs",
    "sr-latn-cs",
    "sv-se",
    "th-th",
    "tr-tr",
    "uk-ua",
    "vi-vn",
    "zh-cn",
    "zh-hk",
    "zh-tw"
  ];
}
